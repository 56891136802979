import Layout from 'components/layout'
import NextErrorComponent from 'next/error'

export default function Custom404(): React.ReactElement {
    return (
        <Layout>
            <div className="next-error-wrapper">
                <NextErrorComponent statusCode={404} />
            </div>
        </Layout>
    )
}
